import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Header from "../components/Header"
import SEO from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Página não encontrada" />
      <Header title="Página não encontrada" subtitle="Sugestões de páginas que você pode curtir logo abaixo" />
      <ul className="suggestion-list">
        <li><a href="/sobre">Sobre este blog e informações de contato</a></li>
        <li><a href="/tags/resenha">Todos os posts de resenhas</a></li>
        <li><a href="/tags/dica">Todos os posts de dicas</a></li>
        <li><a href="/tags/lista">Todos os posts de lista</a></li>
        <li><a href="/tags/tbr">Os meus TBRs</a></li>
        <li><a href="#twitter" target="_blank">Twitter</a></li>
        <li><a href="#instagram" target="_blank">Instagram</a></li>
        <li><a href="#profissional" target="_blank">Site profissional</a></li>
      </ul>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
